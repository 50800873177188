import React from "react"
import { format, distanceInWords, differenceInDays } from "date-fns"
// import {buildImageObj} from '../lib/helpers'
// import {imageUrlFor} from '../lib/image-url'
import Img from "gatsby-image"

function AuthorList({ items, title, publishedAt }) {
  return (
    <div className="">
      <h4 className="uppercase text-gray-800 text-sm">{title}</h4>
      <div className="">
        {items.map(({ author, _key }) => {
          const authorName = author && author.name
          return (
            <div key={_key} className="flex overflow-hidden">
              <div>
                <div className="">
                  {author && author.image && author.image.asset && (
                    <Img
                      fixed={author.image.asset.fixed}
                      alt={author.image.alt}
                      className="inline-block h-10 w-10 rounded-full text-white shadow-solid"
                    />
                  )}
                </div>
              </div>
              <div className="pl-2 flex-col justify-start">
                <div className="text-sm leading-tight font-medium text-gray-700">
                  {authorName || <em>Missing name</em>}
                </div>
                <div className="text-xs leading-tight font-medium text-gray-500">
                  {author.position}
                </div>
                {/* {publishedAt && (
                  <div className="text-xs leading-tight font-medium text-gray-500">
                    {differenceInDays(new Date(publishedAt), new Date()) > 3
                      ? distanceInWords(new Date(publishedAt), new Date())
                      : format(new Date(publishedAt), "MMMM Do, YYYY")}
                  </div>
                )} */}
              </div>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default AuthorList
