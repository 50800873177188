import React from "react"
import { graphql } from "gatsby"
import Container from "../components/container"
import GraphQLErrorList from "../components/graphql-error-list"
import BlogPost from "../components/blog-post"
import SEO from "../components/seo"
import Layout from "../containers/layout"

export const query = graphql`
  query BlogPostTemplateQuery($id: String!) {
    post: sanityPost(id: { eq: $id }) {
      id
      publishedAt
      categories {
        _id
        title
      }

      title
      slug {
        current
      }
      excerptNew
      _rawExcerpt(resolveReferences: { maxDepth: 5 })
      _rawBody(resolveReferences: { maxDepth: 5 })
      mainImage {
        asset {
          _key
          _type
          fixed(width: 1200) {
            src
          }
          fluid(maxWidth: 600) {
            ...GatsbySanityImageFluid
          }
          id
        }
        alt
        _key
        _type
      }
      authors {
        _key
        author {
          image {
            alt
            asset {
              fixed(width: 40, height: 40) {
                ...GatsbySanityImageFixed
              }
            }
          }
          name
          position
        }
      }
    }
  }
`

const BlogPostTemplate = props => {
  const { data, errors } = props
  const post = data && data.post
  console.log(post.slug.current)
  return (
    <Layout>
      {errors && <SEO title="GraphQL Error" />}
      {errors && (
        <Container>
          <GraphQLErrorList errors={errors} />
        </Container>
      )}
      <SEO
        title={post.title}
        path={`blog/${post.slug.current}`}
        description={post.excerptNew}
        image={post.mainImage.asset.fixed.src}
        author={post.authors[0].name}
      />
      {post && <BlogPost {...post} />}
    </Layout>
  )
}

export default BlogPostTemplate
