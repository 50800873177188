import React from 'react'
import clientConfig from '../../client-config'
import BasePortableText from '@sanity/block-content-to-react'
import Figure from './Figure'

// import serializers from './serializers'

const BlockRenderer = props => {
  const {style = 'normal'} = props.node

  if (/^h\d/.test(style)) {
    // const level = style.replace(/[^\d]/g, '')
    if (style === 'h1') {
      return React.createElement(style, {className: `text-4xl leading-8 font-extrabold tracking-tight text-gray-900`}, props.children)
    }
    if (style === 'h2') {
      return React.createElement(style, {className: `text-3xl leading-8 font-extrabold tracking-tight text-gray-900`}, props.children)
    }
    if (style === 'h3') {
      return React.createElement(style, {className: `text-2xl leading-8 font-extrabold tracking-tight text-gray-900`}, props.children)
    }
    if (style === 'h4') {
      return React.createElement(style, {className: `text-xl leading-8 font-extrabold tracking-tight text-gray-900`}, props.children)
    }
    return React.createElement(style, {className: `text-xl leading-8 font-extrabold tracking-tight text-gray-900`}, props.children)
  }
  // if (style === 'normal') {
  //   return <p className='text-5xl text-blue-600'>{props.children}</p>
  // }
  if (style === 'blockquote') {
    return <blockquote className='relative p-4 text-xl italic border-l-4 bg-gray-100 text-gray-900 border-indigo-600'>{props.children}</blockquote>
  }

  // Fall back to default handling
  return BasePortableText.defaultSerializers.types.block(props)
}

const serializers = {
  types: {
    authorReference: ({node}) => <span>{node.author.name}My name is daniel</span>,
    mainImage: Figure,
    container: ({node}) => <span className='text-green-800 text-3xl'>{node.children}My name is daniel</span>,
    // block: BlockRenderer
  }
}
const PortableText = ({blocks}) => (
  <BasePortableText blocks={blocks} serializers={serializers} {...clientConfig.sanity} />
)

export default PortableText
