import React from "react"
import { format, distanceInWords, differenceInDays } from "date-fns"
import PortableText from "./portableText"
import Container from "./container"
import AuthorList from "./author-list"
import Img from "gatsby-image"
import { Link } from "gatsby"
import { Label } from "../utils"

function BlogPost(props) {
  const {
    _rawBody,
    authors,
    categories,
    title,
    mainImage,
    publishedAt,
    excerptNew,
  } = props
  return (
    <article className="">
      <div className="relative bg-white overflow-hidden mx-auto lg:max-w-6xl bg-gray-50 ">
        <div className="relative pb-16 md:pb-20 lg:pb-15">
          <div className="mt-8 mx-auto max-w-screen-xl px-4 sm:mt-12 sm:px-6 md:mt-20">
            <div className="lg:grid lg:grid-cols-12 lg:gap-8">
              <div className="sm:text-center md:max-w-2xl md:mx-auto lg:col-span-6 lg:text-left">
                {publishedAt && (
                  <Label
                    color="gray"
                    className="sm:text-base lg:text-sm xl:text-base"
                  >
                    {categories.map(category => (
                      <span key={category._id}>{category.title}</span>
                    ))}{" "}
                    ·&nbsp;
                    {differenceInDays(new Date(publishedAt), new Date()) > 3
                      ? distanceInWords(new Date(publishedAt), new Date())
                      : format(new Date(publishedAt), "MMMM Do, YYYY")}
                  </Label>
                )}
                <h2 className="mt-1 text-4xl tracking-tight leading-10 font-extrabold text-gray-900 sm:leading-none sm:text-6xl lg:text-5xl xl:text-6xl">
                  {title}
                </h2>
                <p className="mt-3 text-base text-gray-500 sm:mt-5 sm:text-xl lg:text-lg xl:text-xl">
                  {excerptNew}
                </p>
                <div className="mt-5 sm:mt-5 sm:flex sm:justify-center lg:justify-start">
                  {authors && (
                    <AuthorList
                      items={authors}
                      title="Author"
                      publishedAt={publishedAt}
                    />
                  )}
                </div>
              </div>
              <div className="mt-12 relative sm:max-w-lg sm:mx-auto lg:mt-0 lg:max-w-none lg:mx-0 lg:col-span-6 lg:flex lg:items-center">
                <div className="relative mx-auto w-full rounded-md shadow-lg lg:max-w-4xl">
                  <div className="relative block w-full rounded-md overflow-hidden">
                    <Img
                      fluid={mainImage.asset.fluid}
                      alt={mainImage.alt}
                      className="rounded w-full"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Container>
        <div className="max-w-2xl mx-auto px-3 lg:px-0 mb-20 md:mb-24">
          <div className="">
            {_rawBody && <PortableText blocks={_rawBody} />}
          </div>
          <aside className="py-10">
            {categories && (
              <div className="">
                <h3 className="uppercase text-gray-800 text-sm">Categories</h3>
                <div>
                  {categories.map(category => (
                    <div
                      key={category._id}
                      className="flex rounded-full inline-flex bg-gray-200 text-gray-700 px-2 py-1 text-xs font-bold mr-3"
                    >
                      {category.title}
                    </div>
                  ))}
                </div>
              </div>
            )}
          </aside>
        </div>
      </Container>

      <div className="bg-gray-50">
        <div className="max-w-6xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8 lg:flex lg:items-center lg:justify-between">
          <h2 className="text-3xl leading-9 font-extrabold tracking-tight text-gray-900 sm:text-4xl sm:leading-10">
            Ready to get started?
          </h2>
          <div className="mt-8 flex lg:flex-shrink-0 lg:mt-0">
            <div className="inline-flex rounded-full shadow">
              <Link
                to="/project-estimate"
                className="inline-flex items-center justify-center no-underline px-5 py-3 border border-transparent text-base leading-6 font-medium rounded-full text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:shadow-outline transition duration-150 ease-in-out"
              >
                Get started
              </Link>
            </div>
          </div>
        </div>
      </div>
    </article>
  )
}

export default BlogPost
